import { Outlet } from "react-router-dom"
import './layout.css'
import { ReactNode, useState } from "react"
import { User, sendEmailVerification } from "firebase/auth"
import StaticNote from "../../components/static-note/staticNote"
import authFirebase from "../../services/authFirebase"

type AuthenticationLayoutType = {
    user: User | null
}

type RenderFormType = {
    user: User | null
    children: ReactNode
}

export default function AuthenticationLayout(props: AuthenticationLayoutType) {
    
    const {
        user
    } = props
    
    return (
        <div className={'layout'}>
            <RenderOpening/>
            <RenderFormContainer user={user}>
                <Outlet/>
            </RenderFormContainer>
        </div>
    )
}
function RenderOpening() {
    return (
        <div className={'opening'}>

        </div>
    )
}


function RenderFormContainer(props: RenderFormType) {
    const {
        user, 
        children
    } = props
    const [isSentAgain, setIsSentAgain] = useState<boolean>(false)

    const onSendVerificationEmail = async (user: User) => {
        await authFirebase.sendVerificationEmail(user)
        setIsSentAgain(true)
    }
    
    return (
        <main className={'form-container'}>
            {
                (user && !user.emailVerified && !isSentAgain) ? 
                    <StaticNote  
                        title="Email verification sent"
                        text="Check your inbox for a verification email from us. If you don't see it, please check your spam folder. After verifying, if you encounter any issues logging in, try refreshing the page."
                        showButton={true}
                        buttonText={'Click here to send another verification code'}
                        onClick={(e) => onSendVerificationEmail(user)}
                    /> :
                     (user && !user.emailVerified && isSentAgain) ? 
                    <StaticNote 
                        title={`${'Resend email. Please check your inbox'}`}
                        text="Check your inbox for a verification email from us. If you don't see it, please check your spam folder. After verifying, if you encounter any issues logging in, try refreshing the page."
                    /> :
                    null
            }
            {children}
        </main>
    )
}