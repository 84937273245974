import styles from './separate.module.css'

export default function Separate() {
    return (
        <div className={styles['separate-container']}>

                <div className={styles['horizontal-line']}/>
                <span className={styles['text']}>OR</span>
                <div className={styles['horizontal-line']}/>
                
        </div>
    )
}