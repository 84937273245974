import RightArrowIcon from '../../../icons/rightArrow'
import HorizontalLine from '../../horizontal-line/horizontalLine'
import styles from './help.module.css'

interface HelpProps {
    visibility: boolean,
    toggleHelp: () => void
}
  
interface Question {
    title: string, 
    content: string,
}

const QUESTIONS: Question[] = [
    {
      title: 'Q1: What is Robins?',
      content: `Robins is a unique platform designed to help you capture one thought, idea, or task per day. With features tailored for diary entries, daily notes, or organizing ideas, it’s perfect for students, organizers, and multitaskers alike. Robins supports multiple languages, making it accessible to a global audience.`,
    },
    {
      title: 'Q2: How do I create a new note?',
      content: `A note is created automatically whenever you select the date and start writing. No need for extra steps—just pick your day, jot down your thoughts, and Robins saves it for you!`,
    },
    {
      title: 'Q3: Can I edit previous notes?',
      content: `Yes, you can edit your notes from previous days. Just navigate to the date you wish to modify, make your changes, and save. Each note is stored and easily accessible by date.`,
    },
    {
      title: 'Q4: Is Robins free to use?',
      content: `Robins is currently free to use! In the future, we may introduce a paid plan for additional features. However, we’ll notify users well in advance of any changes.`,
    },
    {
      title: 'Q5: Can I use Robins offline?',
      content: `Robins requires an internet connection to save and sync your notes securely. Offline functionality is something we’re considering for future updates.`,
    },
    {
      title: 'Q6: How secure is my data on Robins?',
      content: `We take data security seriously. All your notes are stored securely in the cloud, and we use robust encryption to keep your data private and protected.`,
    },
    {
      title: 'Q7: How can I delete a note?',
      content: `To delete a note, go to the specific date, select the note, and remove the content inside. Once cleared, it will no longer appear in your saved notes.`,
    },
    {
      title: 'Q8: Which languages are supported?',
      content: `Robins currently supports multiple languages, including English, Spanish, and Chinese, among others. We’re constantly working to expand our language options to serve a broader user base.`,
    },
    {
      title: 'Q9: Can I collaborate on notes with others?',
      content: `Currently, Robins is designed for individual use only. Collaborative features are on our development roadmap, so stay tuned for updates!`,
    },
    {
      title: 'Q10: How do I contact support?',
      content: `For any issues or feedback, feel free to reach out via our "Contact Us" page. Our team is here to help you make the most of your experience with Robins!`,
    },
  ];

  
export default function Help(props: HelpProps) {

    const {
        visibility,
        toggleHelp
    } = props
    return(
        <div className={`${styles['help']} ${visibility ? styles['visible'] : styles['hidden']}`}>
            <div className={styles['title-container']}>
            <button onClick={toggleHelp}>
                <RightArrowIcon/>
            </button>
                <h1 className={styles['title']}>Help</h1>
            </div>
            <div className={styles['content']}>
            {QUESTIONS.map((q, index) => (
                <QuestionCard
                key={index}
                title={q.title}
                content={q.content}
            />
        ))}
      </div>
        </div>
    )
}

function QuestionCard({ title, content }: Question) {
    return (
      <>
        <h3>
          <strong>{title}</strong>
        </h3>
        <p>
          <strong>A:</strong> {content}
        </p>
        <HorizontalLine className={styles['custom-line']} />
      </>
    );
  }