export type DBType = {
  [key: string]: string
}

var DB: DBType = {
  '2024-03-24': 'Hello',
  '2024-03-04': 'Note 1',
  '2024-03-07': 'text text text',
  '2024-03-08': 'random text!',
  '2023-01-10': 'Hellooooo',
}

export type DailyType = {
  date: string
  text: string
}

export type UserData = {
  uid: string;
  displayName: string;
  email: string;
  apikey: string
  
}

const BASE_URL = process.env.REACT_APP_API_URL

export const getAllDailies = async (currUser: UserData) => {
  const response = await fetch(
    `${BASE_URL}/dates/${currUser.uid}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': currUser.apikey
      },
    }
  )

  const dailies = await response.json()
  DB = dailies
  return dailies
  // return DB
}

export const createOrUpdateDaily = async (currUser: UserData, daily: DailyType) => {
  DB[daily.date] = daily.text
  const response = await fetch(
    `${BASE_URL}/dates/${currUser.uid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': currUser.apikey
      },
      body: JSON.stringify(DB),
    }
  )
  const newDaily = await response.json()
  return newDaily

  // return DB
}

export const createUser = async (userData: Omit<UserData, 'apikey'>) => {
  const response = await fetch(`${BASE_URL}/users`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify(userData),
    }
  )
  if(!response.ok) {
    throw new Error('Network response was not ok')
  }
  return await response.json()
}

export const getUser = async (userId: string) => {
  const response = await fetch(`${BASE_URL}/users/${userId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const res = await response.json()
  if(!response.ok) {
    throw new Error('Network response was not ok')
  }
  return res
}

/*
  TODO: instead of creating another function, retry it with axios
*/

export const getUserWithRetry = async (userId: string, retries: number = 3, delay: number = 1000) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const user = await getUser(userId);
      return user;
    } catch (error) {
      if (attempt === retries) {
        throw error;
      }
      console.log(`Attempt ${attempt} failed, retrying...`);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
};

export const updateDisplayName = async (currUser: UserData, payload: object) => {
  const res = await fetch(
    `${BASE_URL}/users/${currUser.uid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': currUser.apikey
      },
      body: JSON.stringify(payload)
    }
  )
  return res
}

export const removeUser = async (currUser: UserData | undefined) => {
  await fetch(
    `${BASE_URL}/users/${currUser?.uid}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': currUser?.apikey ?? ''
      },
    }
  )
}