import styles from './navLink.module.css'

export default function NavLink({
    onClick,
    icon,
    text,
    isDisabled,
    isSelected
}: {
    onClick: () => void;
    icon: JSX.Element;
    text: string;
    isDisabled: boolean;
    isSelected: boolean;
}) {
    return (
        <div
            onClick={onClick}
            className={`${styles['nav-link']} ${isDisabled ? styles['disabled'] : ''} ${isSelected ? styles['selected'] : ''}`}
        >
            {icon}
            {text}
        </div>
    );
}