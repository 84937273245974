import { basicModal } from '../../../models/modals'
import CustomButton from '../../custom-button/custombutton'
import styles from './basic.module.css'

type BasicModalProps = {
    modalDetails: basicModal
}
export default function BasicModal(props: BasicModalProps) {
  
    const {
        title,
        subTitle,
        buttonText1,
        buttonText2,
        onClickButton1,
        onClickButton2
    } = props.modalDetails
    return (
        <div className={styles['basic']}>
            <h1 className={styles['title']}>{title}</h1>
            { subTitle && <h2 className={styles['subtitle']}>{subTitle}</h2>}
            <div className={styles.buttonContainer}>
                <CustomButton className={styles['custom-button']} onClick={onClickButton1}>
                    {buttonText1}
                    </CustomButton>
                <CustomButton className={styles['custom-button']} onClick={onClickButton2} buttonColor={'light'}>
                    {buttonText2}
                    </CustomButton>
            </div>
        </div>
    )
}
