export default function TaskIcon() {
    return <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.4721 50.0392L7.87269 44.4149C6.11376 44.0565 4.97866 42.3403 5.3371 40.5814L6.4187 35.2738L40.3872 42.1961L39.3056 47.5036C38.9472 49.2626 37.23 50.3974 35.4721 50.0392Z" fill="#1C1C25"/>
    <path d="M36.1212 46.8547L8.52182 41.2304C7.34884 40.9914 6.59239 39.8477 6.83142 38.6747L14.1863 2.58319L46.0318 9.07277L38.6769 45.1643C38.4379 46.3373 37.2932 47.0935 36.1212 46.8547Z" fill="#FBFCFC"/>
    <path d="M35.9049 47.9162L8.30548 42.2919C6.54867 41.9339 5.41188 40.2152 5.76989 38.4584L13.1247 2.36683C13.2442 1.78087 13.8156 1.40201 14.4026 1.52163L46.2481 8.01121C46.834 8.13062 47.2127 8.70309 47.0933 9.28905L39.7384 45.3806C39.3806 47.1363 37.6606 48.274 35.9049 47.9162ZM15.0315 3.86098L7.89292 38.891C7.77351 39.477 8.15216 40.0494 8.73812 40.1689L36.3375 45.7932C36.9224 45.9124 37.496 45.5339 37.6154 44.948L44.7539 9.91793L15.0315 3.86098Z" fill="#1C1C25"/>
    <path d="M36.6942 6.06433C36.1072 5.94471 35.5358 6.32357 35.4164 6.90953L34.9838 9.03256C34.8644 9.61852 34.2919 9.99717 33.7059 9.87776L25.2138 8.14721C24.6278 8.0278 24.2492 7.45533 24.3686 6.86937L24.8012 4.74634C24.9207 4.16038 24.542 3.58791 23.956 3.4685C23.369 3.34888 22.7976 3.72774 22.6782 4.3137L22.2456 6.43673C21.8876 8.19354 23.0244 9.91223 24.7812 10.2702L33.2733 12.0008C35.028 12.3584 36.7488 11.222 37.1068 9.4652L37.5394 7.34217C37.6588 6.75621 37.2802 6.18374 36.6942 6.06433Z" fill="#1C1C25"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.8026 29.821L14.285 34.6668L19.7295 34.2461L36.5373 23.1271L33.6121 18.7045L16.8026 29.821Z" fill="#FBFCFC"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.3218 34.1686L15.8394 29.3229C15.9248 29.1589 16.0504 29.0198 16.2038 28.9184L33.0133 17.8019C33.5129 17.4715 34.1849 17.6084 34.5153 18.108L37.4405 22.5306C37.771 23.0303 37.6341 23.7022 37.1345 24.0327L20.3258 35.1504C20.1725 35.2518 19.9953 35.313 19.811 35.3274L14.3665 35.748C13.5219 35.8125 12.932 34.9206 13.3218 34.1686ZM16.1442 33.437L19.3658 33.188L35.0361 22.8223L33.3052 20.2053L17.633 30.5695L16.1442 33.437Z" fill="#1C1C25"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M31.7663 25.5711L29.4969 22.1401C29.1665 21.6405 29.3034 20.9686 29.803 20.6381C30.3027 20.3076 30.9746 20.4446 31.3051 20.9442L33.5744 24.3752C33.9049 24.8748 33.768 25.5467 33.2683 25.8772C32.7689 26.2066 32.0959 26.0695 31.7663 25.5711Z" fill="#1C1C25"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.8026 29.821L14.285 34.6668L19.7296 34.2461L16.8026 29.821Z" fill="#1C1C25"/>
    </svg>
    
}