import { useRef, useEffect } from "react";
import styles from './datePicker.module.css'

export default function DatePicker({dates, onSelectDate, selected, forceScroll}: {dates: string[], onSelectDate: (date: string) => void, selected:string|undefined, forceScroll: boolean}) {
    const today = new Date().toISOString().split('T')[0];
  
    const selectedRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [selected]);

    useEffect(() => {
        if (forceScroll && selectedRef.current) {
            selectedRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [forceScroll])

    return (
        <div className={styles['date-picker-container']}>
            <div className={styles['date-picker']}>
                <div className={styles['date-picker-content']} >
                    {
                        dates.map((date: string) => {
                            return (
                                <div
                                    ref={date === selected ? selectedRef : null}
                                    className={`${styles['date-picker-item']} ${date === selected ? styles['selected'] : ''} ${date === today ? styles['today'] : ''}`}
                                    key={date}
                                    onClick={() => onSelectDate(date)}
                                    >
                                    {date}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}