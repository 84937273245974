import { useEffect, useRef, useState } from 'react'
import ReactQuill, {Quill} from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import './customtexteditor.css'

import BoldIcon from '../../icons/bold';
import ItalicIcon from '../../icons/italic';
import LinkIcon from '../../icons/link';
import StrikeThroughIcon from '../../icons/strikeThrough'
import MarkerPenIcon from '../../icons/markerPen';
import OrderedList from '../../icons/orderedList'
import BulletList from '../../icons/bulletList';

type SVGIconComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
interface TextToolButtonProps {
    Icon: SVGIconComponent;
  }

const modules = {
    toolbar: {container: '#toolbar'}
};
var icons = Quill.import('ui/icons')
icons['bold'] = BoldIcon
icons['strike'] = StrikeThroughIcon
icons['italic'] = ItalicIcon
icons['link'] = LinkIcon
icons['background'] = MarkerPenIcon
icons['list'] = OrderedList
icons['bullet'] = BulletList

// Add if necessery
const formats = [
    'background',
    'bold',
    'color',
    'font',
    'italic',
    'link',
    'size',
    'strike',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
  ];

interface CustomTextEditorProps {
    value: string,
    onChange: (newValue: string) => void;
}
export default function CustomTextEditor({value, onChange}: CustomTextEditorProps) {
    const quillRef = useRef<ReactQuill | null>(null);

    useEffect(() => {
        quillRef.current?.focus()
    }, [])

    return (
        <div data-text-editor={'custom-text-editor'}>
            <ReactQuill
                ref={quillRef}
                modules={modules}
                value={value}
                onChange={onChange}
                theme={'snow'}
                bounds={`[data-text-editor="custom-text-editor"]`}
                formats={formats}
            />
            <CustomToolbar/>
        </div>
  )
}

const CustomToolbar = () => (
    <div id="toolbar">
        <select className="ql-header" defaultValue="">
            <option value="1">Header 1</option>
            <option value="2">Header 2</option>
            <option value="3">Header 3</option>
            <option value="">Normal</option>
        </select>
        <div className="delimiter"/>
        <button className="ql-bold">
            <TextToolButton Icon={BoldIcon}/>
        </button>
        <button className="ql-strike">
            <TextToolButton Icon={StrikeThroughIcon}/>
        </button>
        <button className="ql-italic">
            <TextToolButton Icon={ItalicIcon}/>
        </button>
        <button className="ql-background" value="yellow">
            <TextToolButton Icon={MarkerPenIcon}/>
        </button>
        <button className="ql-list" value={'ordered'}>
            <TextToolButton Icon={OrderedList}/>
        </button>
        <button className="ql-list" value={'bullet'}>
            <TextToolButton Icon={BulletList}/>
        </button>
        <div className="delimiter"/>
        <button className="ql-link">
            <TextToolButton Icon={LinkIcon}/>
        </button>
    </div>
);

function TextToolButton({Icon}: TextToolButtonProps) {
    return (
        <div>
            <Icon/>
        </div>
    )
}
