import CloseIcon from '../../icons/close.svg';
import { basicModal } from '../../models/modals';
import BasicModal from './basic/basic';
import styles from './modal.module.css';
import ReactDOM from 'react-dom';
import { useState, useEffect } from 'react';

type ModalProps = {
  isOpen: boolean;
  onClose: any;
  modalType: string;
  modalDetails: basicModal;
};

export default function Modal(props: ModalProps) {
  const { isOpen, onClose, modalType, modalDetails } = props;
  const portalElement = document.getElementById('portal');

  const [show, setShow] = useState(isOpen);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShow(true);
      setTimeout(() => setAnimate(true), 10); // Delay to trigger CSS animation
    } else {
      setAnimate(false);
      setTimeout(() => setShow(false), 300); // Duration of the CSS transition
    }
  }, [isOpen]);

  if (!show && !isOpen) {
    return null;
  }

  const renderContent = () => {
    switch (modalType) {
      case 'signout':
        return 'signout';
      default:
        return <BasicModal modalDetails={modalDetails} />;
    }
  };

  if (!portalElement) {
    console.error("Portal element not found");
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <button
        onClick={onClose}
        className={`${styles.overlay} ${animate ? styles.fadeIn : styles.fadeOut}`}
      />
      <div className={`${styles.modal} ${animate ? styles.slideIn : styles.slideOut}`}>
        <button className={styles['close-icon']} onClick={onClose}>
          <img src={CloseIcon} alt={'close'} />
        </button>
        <div className={styles['content']}>
          {renderContent()}
        </div>
      </div>
    </>,
    portalElement
  );
}
