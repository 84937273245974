import CustomButton from '../../custom-button/custombutton'
import styles from './settings.module.css'
import CloseIcon from '../../icons/close.svg'
import { useState } from 'react'
import Modal from '../../modal/modal'
import { updateDisplayName, UserData } from '../../../api'
import authFirebase from '../../../services/authFirebase'
import Tabs from '../../tabs/tabs'
import ProfileIcon from '../../../icons/profile.svg';
import PreferencesIcon from '../../../icons/preference.svg';
import NotificationIcon from '../../../icons/notification.svg';
import SecurityIcon from '../../../icons/security.svg';
import RightArrowIcon from '../../../icons/rightArrow'
import ProfileSettings from './profileSettings/profileSettings'

interface SettingsProps {
  visibility: boolean,
  user: UserData | undefined,
  toggleSetting: () => void
}

interface CardType {
  children: React.ReactNode
}

type SettingsTab = typeof settingsTabs[number]['name'];

// TODO: bring back tabs when needed
const settingsTabs = [
  { name: 'profile', icon: ProfileIcon },
  // { name: 'notifications', icon: NotificationIcon},
  // { name: 'preferences', icon: PreferencesIcon },
  // { name: 'security', icon: SecurityIcon }
]

export default function Settings(props: SettingsProps) {  
  const { visibility = true, user, toggleSetting } = props;
  const [selectedTab, setSelectedTab] = useState<SettingsTab>('profile');

  const renderTabContent = () => {
    switch (selectedTab) {
      case settingsTabs[0]['name']: return <ProfileSettings user={user} />;
      case settingsTabs[1]['name']: return <NotificationsSettings />;
      case settingsTabs[2]['name']: return <PreferencesSettings />;
      case settingsTabs[3]['name']: return <SecuritySettings />;
      default: return null;
    }
  };
  
  return (
    <div className={`${styles['settings']} ${visibility ? styles['visible'] : styles['hidden']}`}>
        <div className={styles['title-container']}>
          <button onClick={toggleSetting}>
            <RightArrowIcon/>
          </button>
          <h1 className={styles['title']}>Settings</h1>
          <div/>
        </div>
        {/* <Tabs
          tabs={settingsTabs}
          onTabSelect={setSelectedTab}
          selectedTab={selectedTab}
        /> */}
        <div className={styles['content']}>
          <Card>
            {renderTabContent()}
          </Card>
      </div>
    </div>
  )
}

function Card(props: CardType) {
  const {
    children
  } = props
  return (
    <div className={styles['card']}>
      {children}
    </div>
  )
}

function NotificationsSettings() {
  return (
    <div>
      Notifications Settings
    </div>
  )
}

function PreferencesSettings() {
  return (
    <div>
      Preferences Settings
    </div>
  )
}

function SecuritySettings() {
  return (
    <div>
      Security Settings
    </div>
  )
}
