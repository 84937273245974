import styles from './staticNote.module.css'
import CustomButton from '../custom-button/custombutton'

type staticNoteType = {
    className?: string,
    title?: string,
    text?: string,
    showButton?: boolean,
    buttonText?: string,
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

export default function StaticNote(props: staticNoteType) {

    const {
        className,
        title,
        text,
        showButton = false,
        buttonText,
        onClick,
    } = props

    return (
        <div className={`${className} ${styles['static-note']}`}>
            <h2 className={styles['title']}>{title}</h2>
            <span className={styles['text']}>{text}</span>
            {
                showButton ? (
                    <CustomButton
                        className={styles['custom-btn']}
                        onClick={onClick}
                        type={'button'}
                    >
                        <span>{buttonText}</span>
                    </CustomButton>
                ) : null
            }
        </div>
    )
}