import { FormEvent, useState } from 'react'
import styles from './signin.module.css'
import { FormType } from '../../../models/form'
import Validate from '../../../utils/validate'
import { CustomInput } from '../../../components/custom-input/customInput'
import CustomButton from '../../../components/custom-button/custombutton'
import { Link, useNavigate } from 'react-router-dom'
import { FORGOT, ROOT, SIGN_UP } from '../../../constants/routes'
import authFirebase from '../../../services/authFirebase'
import GoogleIcon from '../../../icons/google.svg'
import { User } from 'firebase/auth'
import Separate from '../../../components/separate/separate'

type SignInProps = {
    user: User | null
} 

export default function Signin(props: SignInProps) {
    
    const {
        user
    } = props
    
    const [form, setForm] = useState<FormType>({
        email: {
            name: 'email',
            value: '',
            error: '',
            valid: false,
            rules: ['email', 'not_empty'],
        },
        password: {
            name: 'password',
            value: '',
            error: '',
            valid: false,
            rules: ['password', 'not_empty'],
        }    
    })

    const [isFirst, setIsFirst] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const navigation = useNavigate()
    const handleChange = (e: FormEvent<HTMLInputElement>, name: string) => {
        const target = e.target as HTMLInputElement
        const newVal = target.value
        
        const newState: FormType = { ...form };
        newState[name].value = newVal;
        setError('')
        let validationObj = Validate(newState[name].rules, newVal)
        newState[name].valid = validationObj.isValid
        if(isFirst) {
            newState[name].error = validationObj.msg
        }
        setForm(newState)
    }
    
    const onSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()

        const newState = {...form}
        let isValid = true;
        setIsFirst(true)
        for (const key in form) {   
            let validationObj = Validate(newState[key].rules, newState[key].value)
            
            newState[key].valid = validationObj.isValid
            newState[key].error = validationObj.msg

            if(!newState[key].valid) {
                isValid = false
            }
        }
        setForm(newState)
        
        if(isValid) {
            try {
                await authFirebase.login(newState.email.value, newState.password.value)
                navigation(ROOT)
                setError('')
            } catch(error: any) {
                setError(error.code)
            }
        }
        
    }

    const googleAuth = async (e: any) => {
        e.preventDefault()
        await authFirebase.signInWithGoogle()
    }
    const showStickyNote = user?.emailVerified
    return (
        <div className={styles['sign-in']}>
            {
                showStickyNote ? <></> : null
            }
            <h1 className={'title'}>Login</h1>
            <form>
            <CustomInput
                    className={styles['custom-input']}
                    name={form.email?.name || ''}
                    value={form.email?.value || ''}
                    error={form.email?.error || ''}
                    placeholder={'Email'}
                    type={'email'}
                    autoFocus={true}
                    onChange={handleChange}
                />

            <CustomInput
                className={styles['custom-input']}
                name={form.password?.name || ''}
                value={form.password?.value || ''}
                error={form.password?.error || ''}
                placeholder={'Password'}
                type={'text'}
                onChange={handleChange}
            />
            {
                error ? <p className={styles['error']}>{error}</p> : null
            }
            <CustomButton
                className={styles['custom-btn']}
                type={'submit'}
                onClick={onSubmit}
            >
                <span>Submit</span>
            </CustomButton>

            <Separate/> 
               
               <button onClick={googleAuth}>
                   <img src={GoogleIcon}/>
               </button>
            <p className={styles['additional-text']}>
                Doesn't have an account? <Link className={styles['link']} to={SIGN_UP}>Sign up</Link>
            </p>
            <p className={styles['additional-text']}>
                Forgot your password? <Link className={styles['link']} to={FORGOT}>Reset here</Link>
            </p>
            </form>
        </div>
    )
}