import { FormEvent, useState } from "react"
import { FormType } from "../../../models/form"
import { CustomInput } from "../../../components/custom-input/customInput"
import Validate from "../../../utils/validate"
import styles from './index.module.css'
import CustomButton from "../../../components/custom-button/custombutton"
import { Link, useNavigate } from "react-router-dom"
import { SIGN_IN } from "../../../constants/routes"
import authFirebase from "../../../services/authFirebase"
import StaticNote from "../../../components/static-note/staticNote"


export default function Forgot() {


    const [form, setForm] = useState<FormType>({
        email: {
            name: 'email',
            value: '',
            error: '',
            valid: false,
            rules: ['email', 'not_empty'],
        },
    })

    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const navigate = useNavigate()
    const [isFirst, setIsFirst] = useState<boolean>(false)
    const [currEmail, setCurrEmail] = useState<string>('')
    
    const handleChange = (e: FormEvent<HTMLInputElement>, name: string) => {
        const target = e.target as HTMLInputElement
        const newVal = target.value
        
        const newState: FormType = { ...form };
        newState[name].value = newVal;
        
        let validationObj = Validate(newState[name].rules, newVal)
        newState[name].valid = validationObj.isValid
        if(isFirst) {
            newState[name].error = validationObj.msg
        }
        setForm(newState)
    }

    const onSubmit = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()

        const newState = {...form}
        let isValid = true;
        setIsFirst(true)
        for (const key in form) {   
            let validationObj = Validate(newState[key].rules, newState[key].value)
            
            newState[key].valid = validationObj.isValid
            newState[key].error = validationObj.msg

            if(!newState[key].valid) {
                isValid = false
            }
        }

        setForm(newState)

        if(isValid) {
            await onSendResetPassword(newState.email.value)
        }        
    }

    const onSendResetPassword = async (email : string) => {
        await authFirebase.sendPasswordResetEmail(email)
        const newState = {...form}
        setCurrEmail(newState.email.value)
        newState.email.value = ''
        setForm(newState)
        setIsSuccess(true)
    }

    return (
        <div className={styles['forgot-container']}>
            {
                isSuccess ? (
                    <StaticNote
                        title={'Password Reset Email Sent'}
                        text={`An email was sent to ${currEmail}. Please check your inbox and follow the instructions to reset your password. If you do not see the email, please check your spam folder.`}
                        showButton={true}
                        buttonText={'Go back to Sign In'}
                        onClick={() => navigate(SIGN_IN)}
                />
                ) : null
            }
            
            <h1 className={styles['title']}>Reset password</h1>
            <p className={styles['desc']}>Enter your email and we'll send you a link to reset your password</p>
            <form>
            <CustomInput
                    className={styles['custom-input']}
                    name={form.email?.name || ''}
                    value={form.email?.value || ''}
                    error={form.email?.error || ''}
                    placeholder={'Email'}
                    type={'email'}
                    autoFocus={true}
                    onChange={handleChange}
                />

            <CustomButton
                className={styles['custom-btn']}
                type={'submit'}
                onClick={onSubmit}
            >
                <span>Submit</span>
            </CustomButton>
            </form>
            <p className={styles['additional-text-forgot']}>
                Doesn't have an account? <Link className={styles['link']} to={SIGN_IN}>Go back to Sign in</Link>
            </p>
        </div>
    )
}