import { ChangeEvent, FormEvent } from "react";
import { OFF, ON } from "../../constants/autocomplete-types";
import styles from './customInput.module.css'
import InfoIcon from '../../icons/info.svg'

type CustomInputProps = {
    className?: string;
    autoFocus?: boolean,
    type?: string,
    autoComplete?: typeof ON | typeof OFF, 
    value: string;
    placeholder: string;
    name: string,
    error:string,
    onChange: (event: ChangeEvent<HTMLInputElement>, name: string) => void,
};

type BubbleErrorProps = {
    errorText: string
}

export function CustomInput(props: CustomInputProps) {

    const {
        className = '',
        value = '',
        placeholder = '',
        autoFocus = false,
        name = '',
        onChange = () => {},
        type = 'text',
        error = '',
        autoComplete = OFF
    } = props;

    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        const event = e as React.ChangeEvent<HTMLInputElement>;
        typeof onChange === 'function' && onChange(event, name)
    };

    const animatedLabel = value.length > 0 ? styles['animated'] : '';
    return (
        <div className={`${className} ${styles['input-container']}`}>
            <label className={animatedLabel}>{placeholder}</label>
            <input
                className={error ? styles['error-input'] : ''}
                autoFocus={autoFocus} 
                value={value}
                onChange={handleChange}
                name={name}
                type={type}
                autoComplete={autoComplete}
            />
            {
                error.length > 0 && (
                    <>
                        <img className={styles['info-icon']} src={InfoIcon}/>
                        <Bubble errorText={error}/>
                    </>
                )
            }
            
        </div>
    );
}

function Bubble(props: BubbleErrorProps) {
    const {
        errorText = ''
    } = props

    return (
        <>
            <div className={styles['bubble']}>
                <span>{errorText}</span>
                <div className={styles['pointer']}/>
            </div>
        </>
    )
}